import React from 'react';
import { Box } from '@mui/material';
import { SwitchOption } from "./SwitchOption";

export const CustomSwitch = ({ options, activeSwitch, setActiveSwitch, defaultSwitch, theme = 'light' }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: theme === 'light' ? '#e4e9eb' : '#414D65',
                border: theme === 'light' ? '2px solid #e4e9eb' : '2px solid #414D65',
                borderRadius: '15px',
                overflow: 'hidden',
                cursor: 'pointer',
            }}
        >
            {options.map(({ label, value }) => (
                <SwitchOption
                    theme={theme}
                    key={value}
                    label={label}
                    isDefault={defaultSwitch === value}
                    isActive={activeSwitch === value}
                    onClick={() => setActiveSwitch(value)}
                />
            ))}
        </Box>
    );
};
