import React, { useMemo, useState } from 'react';
import http from 'Utils/http';
import { Box, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { setFlowMethodTransformationList } from 'Actions/flowMethodList';
import { setLoading } from 'Actions/loading';
import { AddIcon } from 'Shared/Icons';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { FlowRuleForm } from 'Shared/flows/flows-interface/FlowRuleForm';
import { AlertDialog } from 'Shared/AlertDialog';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import FlowTransformation from 'Shared/flows/flows-interface/FlowTransformation';
import South from "@mui/icons-material/South";

export const FlowTransformationBlock = ({index, is_last_index, transformation_id, source_id, previous_id, flow_method_id, source_flow_method_id, is_hidden_field, preset_operation, default_title, updateItems}) => {
    const dispatch = useDispatch();
    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();

    const modalText = 'Are you sure you want to delete chain?';

    const { transformationList } = useSelector(state => state.flowMethodList);
    const { operators } = useSelector(state => state.operators);

    const transformation = transformationList.find(item => item.id === transformation_id) || null;

    const conditions = useSelector((state) => (
        state.flowMethodList?.conditionList?.filter((item) => item.transformation_id === transformation_id) ?? []
    ));

    // Memoize selectors
    const memoizedTransformations = useMemo(() => transformationList, [transformationList]);
    const memoizedOperatorsList = useMemo(() => operators, [operators]);
    const memoizedTransformation = useMemo(() => transformation, [transformation]);
    const memoizedConditions = useMemo(() => conditions, [conditions]);

    // State variables
    const [showRules, setShowRules] = useState(true);
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [preCondition, setPreCondition] = useState([]);

    // Derived values
    const transformByValue = memoizedTransformation?.operation_id || '';
    const transformValueValue = memoizedTransformation?.constant || '';

    useMemo(() => {
        if (memoizedOperatorsList?.other && transformByValue) {
            const transform = memoizedOperatorsList.other.find(item => item.id === transformByValue);
            if (transform) {
                setShowRules(transform.title !== 'with');
            }
        }
    }, [memoizedOperatorsList, transformByValue]);

    const validateXML = (str) => {
        try {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(str, "text/xml");
            const parseError = xmlDoc.getElementsByTagName("parsererror");

            if (parseError.length > 0) {
                return false;
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    const parseAndCheckType = (str) => {
        try {
            const parsedValue = JSON.parse(str);
            if (Array.isArray(parsedValue)) {
                return 'array';
            } else if (typeof parsedValue === 'object') {
                return 'object';
            } else {
                return 'string';
            }
        } catch (error) {
            return 'string';
        }
    };
    const selectTransformation = () => {
        const isObjectType = parseAndCheckType(transformValueValue) === 'object'
            || parseAndCheckType(transformValueValue) === 'array'
            || validateXML(transformValueValue);

        if (previous_id && source_id) {
            return 'FIELD';
        }

        if (index === 0) {
            return isObjectType ? 'OBJECT' : 'CONSTANT';
        }

        return isObjectType ? 'OBJECT' : 'CONSTANT';
    };

    const onClose = () => setIsActiveModal(false);

    const onAgree = () => {
        setIsActiveModal(false);
        deleteTransformationHandler();
    };

    const deleteTransformationHandler = () => {
        dispatch(setLoading(true));
        http.delete(createUrl(`/transformation/${transformation_id}/delete`)).then(() => {
            dispatch(setFlowMethodTransformationList(memoizedTransformations.filter((item) => item.id !== transformation_id)));
            enqueueSnackbar('Transformation Chain has been deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const createPreCondition = () => {
        setPreCondition([1]);
    };

    const upDownTransformation = async (direction) => {
        await http.post(`/transformation/${transformation_id}/${direction}`).then(() => {
            enqueueSnackbar('Chain has been moved', {
                action: CustomSnackbar,
                variant: 'success'
            });
        });
    };

    const handleUpClick = () => {
        upDownTransformation('up').then(r => {
            updateItems();
        });
    };

    const handleDownClick = () => {
        upDownTransformation('down').then(r => {
            updateItems();
        });
    };

    return (
        <>
            <AlertDialog visible={isActiveModal} onClose={onClose} onAgree={onAgree} text={modalText}/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    padding: '25px 10px 15px 10px',
                    marginBottom: '22px',
                    background: 'rgba(179, 179, 179, 0.03)',
                    border: '2px dashed rgba(148, 157, 176, 0.25)',
                    borderRadius: '6px',
                    '& .MuiInputBase-input': {
                        backgroundColor: '#F2F5F8',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        top: '-10px',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '0 15px',
                        left: 0,
                    }}
                >
                    <Typography
                        sx={{
                            display: 'flex',
                            color: '#414D65',
                            background: '#F2F7F9',
                            padding: '0 10px'
                        }}
                    >
                        Chain {index + 1}
                    </Typography>
                    {index !== 0 && (
                        <>
                            {index !== 1 && (
                                <IconButton
                                    onClick={handleUpClick}
                                    aria-label="Move Up"
                                    sx={{
                                        display: 'flex',
                                        border: '2px solid rgb(148 157 176 / 25%)',
                                        borderRadius: '2px',
                                        background: '#F2F7F9',
                                        cursor: 'pointer',
                                        padding: '0 3px',
                                        left: '50%',
                                        position: 'absolute',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        transition: 'padding .2s ease-out',
                                        '&:hover': {
                                            backgroundColor: '#e0e3e4',
                                            padding: '2px 6px',
                                        },
                                    }}>
                                    <South alt="Move Up" sx={{ fontSize: '10px', transform: 'rotate(180deg)' }}/>
                                </IconButton>
                            )}
                        </>
                    )}
                    {index !== 0 && (<Typography
                        sx={{
                            display: 'flex',
                            color: '#E07B6A',
                            cursor: 'pointer',
                            background: '#F2F7F9',
                            padding: '0 10px'
                        }}
                        onClick={() => {
                            setIsActiveModal(true);
                        }}
                    >
                        Delete
                    </Typography>)}
                </Box>

                {showRules ? (
                    <>
                        {memoizedConditions.length > 0 ? (memoizedConditions.map((item, indexRule) => (
                                <FlowRuleForm
                                    key={`${item.id}_${indexRule}`}
                                    index={indexRule}
                                    transformation_id={transformation_id}
                                    source_id={source_id}
                                    previous_id={previous_id}
                                    response_flow_method_id={flow_method_id}
                                    transform_by_value={transformByValue}
                                    transform_value_value={transformValueValue}
                                    isLastItem={memoizedConditions.length - 1 + preCondition.length === indexRule}
                                    setPreCondition={setPreCondition}
                                    switchType={item.type}
                                    condition_id={item.id}
                                    indexChain={index}
                                    updateItems={updateItems}
                                />
                            ))
                        ) : (
                            <FlowRuleForm
                                key="default"
                                index={0}
                                transformation_id={transformation_id}
                                source_id={source_id}
                                previous_id={previous_id}
                                response_flow_method_id={flow_method_id}
                                transform_by_value={transformByValue}
                                transform_value_value={transformValueValue}
                                isLastItem={true}
                                setPreCondition={setPreCondition}
                                indexChain={index}
                                updateItems={updateItems}
                            />
                        )}
                        {preCondition.length > 0 && preCondition.map((item, indexRule) => (
                            <FlowRuleForm
                                key={`${indexRule}${preCondition}`}
                                preCondition={true}
                                index={memoizedConditions.length + indexRule}
                                transformation_id={transformation_id}
                                source_id={source_id}
                                previous_id={previous_id}
                                response_flow_method_id={flow_method_id}
                                setPreCondition={setPreCondition}
                                transform_by_value={transformByValue}
                                transform_value_value={transformValueValue}
                                isLastItem={true}
                                indexChain={index}
                                updateItems={updateItems}
                            />
                        ))}

                        {memoizedConditions.length > 0 && preCondition.length === 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    marginLeft: 'auto',
                                    cursor: 'pointer',
                                    '& .MuiTypography-body1': {
                                        transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                                    },
                                    '& .add-icon path': {
                                        transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                                    },
                                    '&:hover': {
                                        '.MuiTypography-body1': {
                                            color: '#197AC0',
                                        },
                                        '.add-icon path': {
                                            fill: '#197AC0',
                                        },
                                    },
                                }}
                                onClick={createPreCondition}
                            >
                                <AddIcon/>
                                <Typography color="primary">Add New Rule</Typography>
                            </Box>
                        )}
                    </>
                ) : null}

                <FlowTransformation
                    id={transformation_id}
                    sourceId={source_id}
                    sourceFlowMethodId={source_flow_method_id}
                    previousId={previous_id}
                    indexChain={index}
                    canShowChainRules={setShowRules}
                    transformBy={transformByValue}
                    transformValue={transformValueValue}
                    selectTransformation={selectTransformation()}
                    isHiddenField={is_hidden_field}
                    presetOperation={preset_operation}
                    defaultTitle={default_title}
                />

                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        bottom: '-10px',
                        justifyContent: 'space-between',
                        width: '100%',
                        height: '20px',
                        left: 0
                    }}
                >
                    {index !== 0 && (
                        <>
                            {!is_last_index && (
                                <IconButton
                                    onClick={handleDownClick}
                                    aria-label="Move Up"
                                    sx={{
                                        display: 'flex',
                                        border: '2px solid rgb(148 157 176 / 25%)',
                                        borderRadius: '2px',
                                        background: '#F2F7F9',
                                        cursor: 'pointer',
                                        padding: '0 3px',
                                        left: '50%',
                                        position: 'absolute',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        transition: 'all .2s ease-out',
                                        '&:hover': {
                                            backgroundColor: '#e0e3e4',
                                            padding: '2px 6px'
                                        },
                                    }}>
                                    <South alt="Move Up" sx={{fontSize: '10px'}}/>
                                </IconButton>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};
