import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const TimezoneSelector = ({ selectedTimezone, onTimezoneChange }) => {
    const [timeZones, setTimeZones] = useState([]);
    const [defaultTimezone, setDefaultTimezone] = useState('');

    useEffect(() => {
        if (selectedTimezone) {
            setDefaultTimezone(selectedTimezone);
        }
        const zones = moment.tz.names();
        const zonesWithOffset = zones.map((zone) => {
            const offset = moment.tz(zone).utcOffset();
            const offsetSign = offset >= 0 ? '+' : '-';
            const formattedOffset = `UTC${offsetSign}${Math.abs(offset) / 60}`;
            return {
                title: `${zone} (${formattedOffset})`,
                value: `${zone}`
            };
        });
        if (!selectedTimezone) {
            const offsetMinutes = new Date().getTimezoneOffset();
            const offsetHours = -offsetMinutes / 60;
            const utcTime = offsetHours;
            const timezoneOffset = `${Intl.DateTimeFormat().resolvedOptions().timeZone} (UTC${offsetHours > 0 ? '+' : ''}${utcTime})`;
            const findDefaultZone = zonesWithOffset.find(zona => zona.title.includes(timezoneOffset.split(' ')[0]));
            if (findDefaultZone) {
                setDefaultTimezone(findDefaultZone.value);
                onTimezoneChange(findDefaultZone.value);
            } else {
                const findTimezoneByTimeOnly = zonesWithOffset.find(zone => zone.title.includes(`UTC${offsetHours > 0 ? '+' : ''}${utcTime}`));
                if (findTimezoneByTimeOnly) {
                    setDefaultTimezone(findTimezoneByTimeOnly.value);
                    onTimezoneChange(findTimezoneByTimeOnly.value);
                }
            }
        }
        setTimeZones(zonesWithOffset);
    }, [selectedTimezone, onTimezoneChange]);

    const handleChange = (event) => {
        onTimezoneChange(event.target.value);
    };

    return (
        <Box>
            <FormControl>
                <InputLabel>Select time zone</InputLabel>
                <Select
                    value={defaultTimezone}
                    label="Select time zone"
                    onChange={handleChange}
                    style={{minWidth: 200, maxWidth: 225, height: 35}}
                >
                    {timeZones.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default TimezoneSelector;
