import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { AddIcon } from 'Shared/Icons';
import { FlowTransformationBlock } from 'Shared/flows/flows-interface/FlowTransformationBlock';

export const FlowTransformationList = ({ transformations, addNewChain, setVisibleFormTransformation, isHiddenField, presetOperation, defaultTitle, updateItems }) => {
    useEffect(() => {
        if (transformations && !transformations?.length) {
            setVisibleFormTransformation(false);
        }
    }, [transformations, setVisibleFormTransformation]);

    const addNewChainV2 = () => {
        const lastTransformation = transformations[transformations?.length - 1];
        let params = {
            flow_method_id: lastTransformation.flow_method_id,
            previous_id: lastTransformation.id,
        };
        addNewChain(params);
    };

    return (
        <>
            {transformations && transformations.length > 0 && transformations.map((item, index) => (
                <FlowTransformationBlock
                    preset_operation={presetOperation}
                    is_hidden_field={isHiddenField}
                    updateItems={updateItems}
                    key={`${index}_${item.id}`}
                    index={index}
                    is_last_index={transformations.length === (index + 1)}
                    transformation_id={item.id}
                    source_id={item.source_id}
                    source_flow_method_id={item.source_flow_method_id}
                    previous_id={item.previous_id}
                    flow_method_id={item.flow_method_id}
                    default_title={defaultTitle}
                />
            ))}

            {transformations && transformations?.length > 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                        marginTop: '17px',
                        marginLeft: 'auto',
                        marginRight: '12px',
                        cursor: 'pointer',
                        '& .MuiTypography-body1': {
                            transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                        },
                        '& .add-icon path': {
                            transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                        },
                        '&:hover': {
                            '.MuiTypography-body1': {
                                color: '#197AC0',
                            },
                            '.add-icon path': {
                                fill: '#197AC0',
                            },
                        },
                    }}
                    onClick={addNewChainV2}
                >
                    <AddIcon />
                    <Typography color="primary">Add New Chain</Typography>
                </Box>
            )}
        </>
    );
};
