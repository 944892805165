import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const SelectActionsInitiateFlowModal = ({ defaultType = 'url', type, setType }) => {
    const [localType, setLocalType] = useState(defaultType);

    useEffect(() => {
        if (type !== undefined) {
            setLocalType(type);
        }
    }, [type]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setLocalType(newValue);
        if (setType) {
            setType(newValue);
        }
    };

    return (
        <Grid item xs={4}>
            <Typography
                variant="h2"
                align="center"
                sx={{
                    marginBottom: '5px',
                    fontWeight: 500,
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#3B465C',
                }}
            >
                Initiate Flow
            </Typography>

            <Typography
                align="center"
                sx={{
                    marginBottom: '35px',
                    color: '#949DB0',
                }}
            >
                Please set up default values for the input fields, that can be used in GET or POST requests
            </Typography>

            <FormControl fullWidth sx={{ marginBottom: '40px' }}>
                <InputLabel>Initiate from</InputLabel>
                <Select
                    label="Initiate from"
                    value={localType}
                    onChange={handleChange}
                >
                    <MenuItem value={'url'}>From Url</MenuItem>
                    <MenuItem value={'cron'}>Cron</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
};
