import React from 'react';
import { Box, Typography, TextField, Grid } from '@mui/material';
import { CopyIcon } from 'Shared/Icons';
import { PredefinedInputSelectApiField } from 'Shared/flows/initiate-flow-modal/PredefinedInputSelectApiField';
import { CreateInputField } from 'Shared/flows/initiate-flow-modal/CreateInputField';

const FlowDetails = ({flow_id, flowUrl, inputs, copyToClipboard, setNeedRefresh}) => {
    return (
        <Grid
            item
            xs={8}
            sx={{
                height: '100%',
                paddingLeft: '25px',
                borderRadius: '10px',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    padding: '10px 5px 20px 20px',
                    background: '#F2F7F9',
                    border: '1px solid rgba(148, 157, 176, 0.25)',
                    borderRadius: '10px',
                    height: 'inherit',
                }}
            >
                <Box
                    className="scroll-container"
                    sx={{
                        paddingTop: '10px',
                        paddingRight: '15px',
                        overflowY: 'auto',
                        height: 'inherit',
                    }}
                >
                    <Typography
                        sx={{
                            marginBottom: '25px',
                            fontWeight: '700',
                            letterSpacing: '0.1em',
                            color: 'rgba(65, 77, 101, 0.85)',
                            textTransform: 'uppercase',
                        }}
                    >
                        Flow ID: {flow_id}
                    </Typography>
                    <Typography
                        sx={{
                            marginBottom: '15px',
                            fontWeight: '500',
                            color: '#949DB0',
                            textTransform: 'uppercase'
                        }}
                    >
                        Flow Endpoint:
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '25px',
                        }}
                    >
                        <TextField
                            value={flowUrl}
                            readOnly
                            fullWidth
                            disabled={true}
                            sx={{
                                marginRight: '10px',
                                '& .MuiInputBase-input': {
                                    color: '#949DB0',
                                },
                            }}
                        />
                        <Box
                            onClick={copyToClipboard}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '53px',
                                height: '53px',
                                background: 'rgba(59, 70, 92, 0.05)',
                                border: '1px solid rgba(65, 77, 101, 0.03)',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                                '&:hover': {
                                    background: 'rgba(59, 70, 92, 0.15)',
                                },
                            }}
                        >
                            <CopyIcon/>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            marginBottom: '3px',
                            fontWeight: '700',
                            letterSpacing: '0.1em',
                            color: 'rgba(65, 77, 101, 0.85)',
                            textTransform: 'uppercase',
                        }}
                    >
                        List of parameters
                    </Typography>
                    <Typography
                        sx={{
                            marginBottom: '15px',
                            fontWeight: '500',
                            color: '#949DB0'
                        }}
                    >
                        Type any parameters here Add all your GET parameters and their default values
                    </Typography>
                    {/* inputs list */}
                    <Box
                        sx={{
                            padding: '26px 2px 15px 10px',
                            marginBottom: '25px',
                            background: 'rgba(65, 77, 101, 0.05)',
                            borderRadius: '6px',
                        }}
                    >
                        {inputs?.length > 0 && inputs.map((input) => (
                            <PredefinedInputSelectApiField
                                key={input.field_id}
                                input={input}
                                setNeedRefresh={setNeedRefresh}
                                flow_id={flow_id}
                            />
                        ))}
                        <CreateInputField inputs={inputs} setNeedRefresh={setNeedRefresh}/>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};

export default FlowDetails;
