import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import http from 'Utils/http';
import {
    Typography,
    Select,
    MenuItem,
    TextField,
    Switch,
    FormControlLabel,
    Button,
    Box,
    Grid,
    Checkbox
} from '@mui/material';
import { CustomSwitch } from 'Shared/CustomSwitch';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import { setFlow } from 'Actions/flow';
import { setLoading } from 'Actions/loading';
import TimezoneSelector from 'Shared/TimezoneSelector';
import { useSnackbar } from 'notistack';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';

const months = [
    "January", "February", "March", "April", "May",
    "June", "July", "August", "September", "October",
    "November", "December"
];

const CronConfigForm = ({ flow_id, cronCommand, serverTimeZone, cronTimeZone, cronStatus = true }) => {
    const dispatch = useDispatch();
    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();

    const currentUser = useSelector((state) => state.auth.user);

    const [isOn, setIsOn] = useState(!!cronStatus);
    const [startTime, setStartTime] = useState({ hours: 0, minutes: 0, period: 'AM' });
    const [cronPeriod, setCronPeriod] = useState('daily');
    const [cronPeriodDefault, setCronPeriodDefault] = useState('daily');
    const [timeZone, setTimeZone] = useState(cronTimeZone);
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    const isCronValid = (freq) => {
        const cronRegex = /^(?:\d{1,2}|\*)(?:\/\d+)?(?:,\d{1,2})*\s+(?:\d{1,2}|\*|\d{1,2}-\d{1,2})(?:\/\d+)?(?:,\d{1,2}-\d{1,2})*\s+(?:\d{1,2}|\*|\d{1,2}-\d{1,2})(?:,\d{1,2})*\s+(?:\d{1,2}|\*|\d{1,2}-\d{1,2})(?:,\d{1,2})*\s+(?:\d|\*|\d-\d)(?:,\d)*$/;
        return cronRegex.test(freq);
    }

    const {
        register,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            command: cronCommand
        },
        mode: 'onChange'
    });

    const handleMonthsToggle = (month) => {
        setSelectedMonths((prevSelected) =>
            prevSelected.includes(month)
                ? prevSelected.filter((m) => m !== month)
                : [...prevSelected, month]
        );
    };

    const handleDayToggle = (day) => {
        if (cronPeriod === 'monthly') {
            setSelectedDays((prev) =>
                prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
            );
        }
        if (cronPeriod === 'weekly') {
            setSelectedWeekDays((prev) =>
                prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
            );
        }
    };

    const generateCronString = useCallback(() => {
        const hours = startTime.period === 'PM'
            ? (isNaN(Number(startTime.hours)) ? startTime.hours : (Number(startTime.hours) % 12) + 12)
            : (isNaN(Number(startTime.hours)) ? startTime.hours : (Number(startTime.hours) % 12));
        const minutes = startTime.minutes ? startTime.minutes : '*';
        const weeklyDays = cronPeriod === 'weekly' && selectedWeekDays.length
            ? selectedWeekDays.sort((a, b) => a - b).join(',')
            : '*';
        const monthlyDays = cronPeriod === 'monthly' && selectedDays.length
            ? selectedDays.sort((a, b) => a - b).join(',')
            : '*';
        const months = cronPeriod === 'monthly' && selectedMonths.length
            ? selectedMonths.sort((a, b) => a - b).join(',')
            : '*';
        return `${minutes} ${hours} ${cronPeriod === 'monthly' ? monthlyDays : '*'} ${cronPeriod === 'monthly' ? months : '*'} ${cronPeriod === 'weekly' ? weeklyDays : '*'}`;
    }, [cronPeriod, selectedDays, selectedMonths, selectedWeekDays, startTime]);

    const validateStartTime = useCallback(() => {
        if (startTime.hours < 0 || startTime.hours > 12) {
            console.log("Hours must be between 1 and 12");
            return false;
        }
        if (startTime.minutes < 0 || startTime.minutes > 59) {
            console.log("Minutes must be between 0 and 59");
            return false;
        }
        return true;
    }, [startTime]);

    const handleSaveCron = useCallback(async () => {
        if (cronPeriod !== 'manual' && !validateStartTime()) return;
        dispatch(setLoading(true));
        const cronString = cronPeriod === 'manual' ? getValues('command') : generateCronString();
        const params = {
            cron_status: isOn ? 1 : 0
        };
        if (timeZone) {
            params.flow_timezone = timeZone;
        }
        if (cronString) {
            params.cron = cronString;
        }
        await http.post(createUrl(`/flow/${flow_id}/update`), params).then((res) => {
            dispatch(setFlow(res.data.flow));
            enqueueSnackbar('Cron job settings have been saved', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error',
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }, [createUrl, dispatch, flow_id, generateCronString, validateStartTime, timeZone, isOn, cronPeriod, enqueueSnackbar, getValues]);

    const parseCronString = useCallback((cronString) => {
        const [minutes, hours, dayMonths, months, dayWeek] = cronString.split(' ');
        const isCustomValue = (value) => value.includes('-') || value.includes('/');
        const normalizedHours = !isCustomValue(hours)
            ? (Number(hours) + (Number(hours) >= 12 ? 0 : 12)) % 24
            : hours;
        const period = !isCustomValue(hours) && hours >= 12 ? 'PM' : 'AM';
        setStartTime({
            hours: !isCustomValue(hours)
                ? (normalizedHours % 12 || 12)
                : hours,
            minutes,
            period,
        });

        if (dayWeek !== '*' && !isCustomValue(hours) && !isCustomValue(minutes)) {
            setCronPeriodDefault('weekly');
            setCronPeriod('weekly');
            setSelectedWeekDays(dayWeek.split(',').map(Number));
            setSelectedDays([]);
            setSelectedMonths([]);
        } else if (dayMonths !== '*' && !isCustomValue(hours) && !isCustomValue(minutes)) {
            setCronPeriodDefault('monthly');
            setCronPeriod('monthly');
            setSelectedDays(dayMonths.split(',').map(Number));
            setSelectedMonths(months !== '*' ? months.split(',').map(Number) : []);
            setSelectedWeekDays([]);
        } else if (hours !== '*' && !isCustomValue(hours) && !isCustomValue(minutes)) {
            setCronPeriodDefault('daily');
            setCronPeriod('daily');
            setSelectedWeekDays([]);
            setSelectedDays([]);
            setSelectedMonths([]);
        } else {
            setCronPeriodDefault('manual');
            setCronPeriod('manual');
            setSelectedWeekDays([]);
            setSelectedDays([]);
            setSelectedMonths([]);
        }
    }, [setStartTime, setCronPeriodDefault, setCronPeriod, setSelectedWeekDays, setSelectedDays, setSelectedMonths]);

    useEffect(() => {
        if (cronCommand) {
            parseCronString(cronCommand);
        } else {
            setStartTime({ hours: 12, minutes: 0, period: 'AM' });
        }
    }, [cronCommand, parseCronString]);

    return (
        <Grid item xs={8}
              sx={{
                  height: '100%',
                  paddingLeft: '25px',
                  borderRadius: '10px',
                  overflow: 'hidden',
              }}>
            <Box sx={{
                padding: '10px 5px 20px 20px',
                background: '#F2F7F9',
                border: '1px solid rgba(148, 157, 176, 0.25)',
                borderRadius: '10px',
                height: 'inherit',
                maxHeight: '680px',
                overflowY: 'auto'
            }}>
                {/* Cron Period Configuration */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px'
                }}>
                    <Typography variant="h6" sx={{
                        color: '#414D65',
                        fontSize: '14px',
                        fontWeight: 500,
                        textTransform: 'uppercase',
                        letterSpacing: '1px'
                    }}>
                        Set Cron Period
                    </Typography>
                    {/* On/Off Switch */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isOn}
                                    onChange={(e) => setIsOn(e.target.checked)}
                                    disabled={!currentUser.cron}
                                />
                            }
                            label="On/Off"
                            labelPlacement="start"
                            sx={{
                                color: isOn ? 'green' : 'red',
                                marginRight: '7px',
                                '& .MuiTypography-root': {
                                    color: isOn ? 'green' : 'red'
                                },
                            }}
                        />
                        {!currentUser?.cron ? <Typography sx={{
                            color: 'red',
                            fontSize: '10px',
                            fontWeight: 400,
                            letterSpacing: '1px'
                        }}>
                            If you need to use Cron, please reach out to support.
                        </Typography> : null}
                    </Box>
                </Box>

                <Box sx={{
                    width: '70%',
                    marginBottom: '20px'
                }}>
                    <CustomSwitch
                        options={[
                            { label: 'Daily', value: 'daily' },
                            { label: 'Weekly', value: 'weekly' },
                            { label: 'Monthly', value: 'monthly' },
                            { label: 'Manual', value: 'manual' },
                        ]}
                        defaultSwitch={cronPeriodDefault}
                        activeSwitch={cronPeriod}
                        setActiveSwitch={(newPeriod) => {setCronPeriod(newPeriod)}}
                    />
                </Box>

                {cronPeriod === "manual" && (
                    <Box>
                        <Typography variant="h6" sx={{
                            letterSpacing: '1px',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#3B465C',
                            marginBottom: '5px'
                        }}>
                            Set Cron Command
                        </Typography>
                        <Box sx={{marginBottom: '16px'}}>
                            <Box sx={{display: "flex", alignItems: "center", gap: 1, marginBottom: '5px'}}>
                                <TextField
                                    error={!!errors?.command}
                                    helperText={errors?.command ? 'Invalid cron expression' : ''}
                                    fullWidth
                                    {...register('command', {
                                        validate: (value) => isCronValid(value) || 'Invalid cron expression'
                                    })}
                                    sx={{
                                        width: '50%',
                                        marginBottom: '0',
                                        '& .MuiInputBase-input': {
                                            padding: '5px',
                                            height: '25px',
                                            color: '#949DB0'
                                        },
                                        '& input': {
                                            padding: '5px',
                                            height: '25px'
                                        }
                                    }}
                                />
                                {/* Time Zone Select */}
                                <TimezoneSelector selectedTimezone={timeZone} onTimezoneChange={(zone) => setTimeZone(zone)} />
                            </Box>
                            <Typography sx={{
                                letterSpacing: '1px',
                                fontSize: '10px',
                                color: '#3B465C',
                                marginBottom: '5px'
                            }}>
                                Insert preconfigured CRON schedule message format.<br/>
                                For example, to run the flow once in 2 hours: "0 */2 * * *"
                            </Typography>
                        </Box>
                    </Box>
                )}

                {cronPeriod !== "manual" && (
                    <Box>
                        <Typography variant="h6" sx={{
                            letterSpacing: '1px',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#3B465C',
                            marginBottom: '5px'
                        }}>
                            Set Time
                        </Typography>
                        <Box sx={{display: "flex", alignItems: "center", gap: 1, marginBottom: '16px'}}>
                            <Typography variant="h6" sx={{
                                letterSpacing: '1px',
                                fontSize: '12px',
                                fontWeight: 400,
                                color: '#949DB0',
                                marginBottom: '5px'
                            }}>
                                Start Time:
                            </Typography>
                            <Box sx={{
                                border: '1px solid #ccd3db',
                                borderRadius: '6px'
                            }}>
                                {/* Hour Input */}
                                <InputMask
                                    mask="99"
                                    maskChar={null}
                                    value={startTime.hours || ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === "" || (Number(value) >= 1 && Number(value) <= 12)) {
                                            setStartTime({ ...startTime, hours: value });
                                        }
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            sx={{
                                                width: "50px",
                                                "& .MuiInputBase-root": {
                                                    border: "none",
                                                    borderRadius: "0",
                                                    "&:after": {
                                                        content: '"\u003A"',
                                                    },
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none",
                                                    borderRadius: "0",
                                                },
                                                "& input": {
                                                    textAlign: "center",
                                                    padding: "5px",
                                                    height: "25px",
                                                    WebkitAppearance: "none",
                                                },
                                                "& input::-webkit-inner-spin-button": {
                                                    WebkitAppearance: "none",
                                                },
                                                "& input::-webkit-outer-spin-button": {
                                                    WebkitAppearance: "none",
                                                },
                                            }}
                                            inputProps={{
                                                maxLength: 2,
                                                type: "text",
                                            }}
                                        />
                                    )}
                                </InputMask>

                                {/* Minute Input */}
                                <InputMask
                                    mask="99"
                                    maskChar={null}
                                    value={startTime.minutes || ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === "" || (Number(value) >= 0 && Number(value) <= 59)) {
                                            setStartTime({ ...startTime, minutes: value });
                                        }
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            sx={{
                                                width: "50px",
                                                borderWidth: "0",
                                                borderRadius: "0",
                                                "& .MuiInputBase-root": {
                                                    borderWidth: "0",
                                                    borderRadius: "0",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderWidth: "0",
                                                    borderRadius: "0",
                                                },
                                                "& input": {
                                                    textAlign: "center",
                                                    padding: "5px",
                                                    height: "25px",
                                                    WebkitAppearance: "none",
                                                },
                                                "& input::-webkit-inner-spin-button": {
                                                    WebkitAppearance: "none",
                                                },
                                                "& input::-webkit-outer-spin-button": {
                                                    WebkitAppearance: "none",
                                                },
                                            }}
                                            inputProps={{
                                                maxLength: 2,
                                                type: "text"
                                            }}
                                        />
                                    )}
                                </InputMask>
                            </Box>

                            {/* AM/PM Select */}
                            <Select
                                value={startTime.period}
                                onChange={(e) => setStartTime({...startTime, period: e.target.value})}
                                sx={{
                                    width: "75px",
                                    borderRadius: '6px',
                                    textAlign: "center",
                                    backgroundColor: '#3B465C',
                                    color: '#ffffff',
                                    "& .MuiSvgIcon-root": {
                                        fill: '#ffffff'
                                    },
                                    "& .MuiSelect-select": {
                                        padding: "5px",
                                        height: "25px",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    },
                                }}>
                                <MenuItem value="AM">AM</MenuItem>
                                <MenuItem value="PM">PM</MenuItem>
                            </Select>

                            {/* Time Zone Select */}
                            <TimezoneSelector selectedTimezone={timeZone} onTimezoneChange={(zone) => setTimeZone(zone)} />
                        </Box>
                    </Box>
                )}
                {/* Time Selector */}

                {/* Weekday Selector */}
                {cronPeriod === "weekly" && (
                    <Box sx={{display: "flex", alignItems: "center", flexWrap: 'wrap', gap: 1, marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            letterSpacing: '1px',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#3B465C',
                            marginBottom: '5px'
                        }}>
                            Week Days
                        </Typography>
                        <Box sx={{display: "flex", gap: 2}}>
                            {weekDays.map((day, index) => (
                                <FormControlLabel
                                    key={day}
                                    control={
                                        <Checkbox
                                            checked={selectedWeekDays.includes(index)}
                                            onChange={() => handleDayToggle(index)}
                                        />
                                    }
                                    label={day}
                                    sx={{
                                        justifyContent: 'space-between',
                                        color: '#414D65',
                                        '& .MuiTypography-root': {
                                            color: '#414D65'
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                )}

                {/* Month Days Selection */}
                {cronPeriod === "monthly" && (
                    <Box sx={{display: "flex", alignItems: "center", flexWrap: 'wrap', gap: 1, marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            letterSpacing: '1px',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#3B465C',
                            marginBottom: '0'
                        }}>
                            Month Days
                        </Typography>
                        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                            {Array.from({length: 31}, (_, i) => i + 1).map((day) => (
                                <Box key={day} sx={{marginRight: '5px'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedDays.includes(day)}
                                                onChange={() => handleDayToggle(day)}
                                            />
                                        }
                                        label={day}
                                        sx={{
                                            justifyContent: 'space-between',
                                            color: '#414D65',
                                            '& .MuiTypography-root': {
                                                color: '#414D65',
                                                fontWeight: 400,
                                                width: '15px',
                                                display: 'block'
                                            },
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>

                        <Typography variant="h6" sx={{
                            letterSpacing: '1px',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#3B465C',
                            marginBottom: '0'
                        }}>
                            Months
                        </Typography>
                        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                            {months.map((month, index) => (
                                <FormControlLabel
                                    key={month}
                                    control={
                                        <Checkbox
                                            checked={selectedMonths.includes(index + 1)}
                                            onChange={() => handleMonthsToggle(index + 1)}
                                        />
                                    }
                                    label={month}
                                    sx={{
                                        justifyContent: 'space-between',
                                        color: '#414D65',
                                        '& .MuiTypography-root': {
                                            color: '#414D65',
                                            fontWeight: 400,
                                            display: 'block'
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                )}

                {/* Save Button */}
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveCron}
                        disabled={!currentUser.cron}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
};

export default CronConfigForm;
