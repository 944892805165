import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box, FormControl, FormHelperText, IconButton, TextField, Checkbox } from '@mui/material';
import { setLoading } from 'Actions/loading';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import http from 'Utils/http';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import { AlertDialog } from 'Shared/AlertDialog';

const isValidJSON = (jsonString) => {
    if (typeof jsonString !== 'string') {
        return false;
    }
    return jsonString.startsWith('{') || jsonString.startsWith('[') || jsonString.startsWith('<');
};


export const PredefinedInputSelectApiField = ({ input, setNeedRefresh, flow_id }) => {
    const dispatch = useDispatch();

    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();
    const [type, setType] = useState(isValidJSON(input?.default) ? 'OBJECT' : 'FIELD');
    const [code, setCode] = useState(isValidJSON(input?.default) ? input?.default : '');
    const [sensitive, setSensitive] = useState(!!input?.sensitive || false);
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [pendingAction, setPendingAction] = useState(null);

    const {
        register,
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
            defaultValue: input?.default,
            sensitive: input?.sensitive || false,
        },
        mode: 'onChange'
    });

    useEffect(() => {
        const subscription = watch((values) => {
            const { defaultValue } = values;
            const isType = isValidJSON(defaultValue);
            setType(isType ? 'OBJECT' : 'FIELD');

            if (isType) {
                setCode(defaultValue);
            } else {
                setCode('');
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, setType, setValue, setCode]);

    const handleSensitiveChange = (checked) => {
        if (!checked) {
            setModalText('Switching off sensitive mode will clear the default value. Proceed?');
            setPendingAction(() => () => {
                setValue('defaultValue', '');
                setSensitive(false);
            });
            setIsActiveModal(true);
        } else {
            setSensitive(checked);
        }
    };

    const handleDelete = (e, input) => {
        const deleteField = () => {
            dispatch(setLoading(true));
            http.delete(`/input/${flow_id}/field/${input.field_id}/delete`)
                .then(() => {
                    enqueueSnackbar('The input parameter has been deleted', {
                        action: CustomSnackbar,
                        variant: 'success',
                    });
                    setNeedRefresh(true);
                })
                .catch((err) => {
                    enqueueSnackbar(err.response?.data?.message || 'An error occurred', {
                        action: CustomSnackbar,
                        variant: 'error',
                    });
                })
                .finally(() => {
                    dispatch(setLoading(false));
                });
        };

        http.get(`/input/${flow_id}/field/${input.field_id}/verify`)
            .then(deleteField)
            .catch((err) => {
                if (err.response?.data?.status === 'error') {
                    setModalText(
                        'Linked to a method or transformation. Deleting may break the Flow. Delete?'
                    );
                    setPendingAction(() => deleteField);
                    setIsActiveModal(true);
                } else {
                    enqueueSnackbar(err.response?.data?.message || 'An error occurred', {
                        action: CustomSnackbar,
                        variant: 'error',
                    });
                }
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    const onSubmit = (data) => {
        const params = {
            id: input.field_id,
            default: data.defaultValue,
            sensitive: sensitive
        };
        dispatch(setLoading(true));
        http.post(createUrl(`/field/${input.request_field_id}/update`), params).then((res) => {
            enqueueSnackbar('The input parameter has been updated', {
                action: CustomSnackbar,
                variant: 'success'
            });
            setNeedRefresh(true);
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
            dispatch(setLoading(false));
        });
    };

    const onClose = () => setIsActiveModal(false);

    const onAgree = () => {
        if (pendingAction) pendingAction();
        setIsActiveModal(false);
    };

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: 'flex',
                    marginBottom: '16px',
                }}
            >
                <FormControl
                    sx={{
                        width: '100%',
                        marginRight: '10px'
                    }}
                >
                    <TextField
                        sx={{
                            backgroundColor: '#F2F5F8'
                        }}
                        disabled
                        error={!!errors?.name}
                        helperText={errors?.name ? 'This is a required field' : ''}
                        label="Parameter Name"
                        fullWidth
                        defaultValue={input?.name}
                        InputLabelProps={{ shrink: true }}
                        {...register('name', {})}
                    />
                </FormControl>

                <FormControl sx={{ width: '100%', marginRight: '10px' }}>
                    <Controller
                        name="defaultValue"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) =>
                                type === 'OBJECT' && !sensitive ? isValidJSON(value) || 'Invalid JSON format' : true,
                        }}
                        render={({ field }) => (
                            <>
                                {type === 'OBJECT' && !sensitive ? (
                                    <Editor
                                        {...field}
                                        value={code}
                                        id="defaultValue"
                                        onValueChange={(code) => setCode(code)}
                                        highlight={(code) => highlight(code, languages.js)}
                                        padding={10}
                                        tabSize={4}
                                        style={{
                                            borderRadius: '4px',
                                            borderColor: 'rgba(0, 0, 0, 0.23)',
                                            borderWidth: '1px',
                                            borderStyle: 'solid',
                                            backgroundColor: '#F2F5F8',
                                            minHeight: '100px',
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        {...field}
                                        type={sensitive ? 'password' : 'text'}
                                        id="defaultValue"
                                        label="Default Value"
                                        error={!!errors?.defaultValue}
                                        helperText={errors?.defaultValue ? 'This is a required field' : ''}
                                        sx={{ marginBottom: '16px' }}
                                    />
                                )}
                                {errors.defaultValue && (
                                    <FormHelperText>{errors.defaultValue.message}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </FormControl>

                <Checkbox
                    sx={{ height: '53px', width: '53px', display: 'inline-flex', flex: '0 0 auto' }}
                    checked={sensitive}
                    onChange={(e) => handleSensitiveChange(e.target.checked)}
                    title="Encode sensitive data"
                />

                <IconButton
                    sx={{
                        height: '53px',
                        width: '53px'
                    }}
                    type="submit"
                    title="Save item"
                >
                    <SaveIcon />
                </IconButton>

                <IconButton
                    sx={{
                        height: '53px',
                        width: '53px',
                        color: '#f44336'
                    }}
                    onClick={(e) => {
                        handleDelete(e, input);
                    }}
                    aria-label="delete"
                    title="Delete item"
                >
                    <DeleteIcon />
                </IconButton>
            </Box>

            {/* AlertDialog Modal */}
            <AlertDialog
                visible={isActiveModal}
                onClose={onClose}
                onAgree={onAgree}
                text={modalText}
            />
        </>
    );
};
