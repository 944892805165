//USER
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

//LOADING
export const SET_LOADING = 'SET_LOADING';

//MESSAGE
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_VISIBLE_MESSAGE = 'SET_VISIBLE_MESSAGE';

//REFRESH ALL
export const REFRESH_ALL_STATE = 'REFRESH_ALL_STATE';

//COMPANIES
export const SET_COMPANIES = 'SET_COMPANIES';

//API
export const SET_APIS = 'SET_APIS';

//OPERATORS
export const SET_OPERATORS = 'SET_OPERATORS';

//MATCH_MODAL
export const SET_VISIBLE_MATCH_MODAL = 'SET_VISIBLE_MATCH_MODAL';
export const SET_CONTENT_MATCH_MODAL = 'SET_CONTENT_MATCH_MODAL';

//FLOW
export const SET_FLOW = 'SET_FLOW';

//SET_FLOW_METHOD_RESPONSE
export const SET_FLOW_METHOD_RESPONSE = 'SET_FLOW_METHOD_RESPONSE';

//SET_FLOW_METHOD_LIST
export const SET_FLOW_METHOD_LIST = 'SET_FLOW_METHOD_LIST';
export const SET_FLOW_METHOD_TRANSFORMATION_LIST = 'SET_FLOW_METHOD_TRANSFORMATION_LIST';

//it's need???
export const SET_FLOW_METHOD_CONDITION_LIST = 'SET_FLOW_METHOD_CONDITION_LIST';
export const SET_FLOW_METHOD_FIELD_LIST = 'SET_FLOW_METHOD_FIELD_LIST';

//SERVER
export const SET_SERVER = 'SET_SERVER';
