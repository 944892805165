import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import http from 'Utils/http';
import axios from 'axios';
import { Box, Grid, Button, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { setAPIs } from 'Actions/APIs';
import { setOperators } from 'Actions/operators';
import { setFlow } from 'Actions/flow';
import { setServer } from 'Actions/server';
import { setFlowMethodList } from 'Actions/flowMethodList';
import { FlowItem } from 'Shared/flows/flows-interface/FlowItem';
import { WhiteAddIcon, CoolIcon } from 'Shared/Icons';
import { InitiateFlowModal } from 'Shared/flows/initiate-flow-modal/InitiateFlowModal';
import { CompleteFlowModal } from 'Shared/flows/finalizate-flow-modal/CompleteFlowModal';
import { useIsFirstRender } from 'Hooks/useIsFirstRender';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import { ROUTES } from 'Constants/routes';
import FlowTools from 'Shared/flows/flows-interface/FlowTools';
import FlowMatchModal from 'Shared/flows/flows-interface/FlowMatchModal';
import FlowItemAccordion from 'Shared/flows/flows-interface/FlowItemAccordion';

const EditFlowPage = () => {
    const { createUrl } = useCreateUrl();
    const { flow_id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isFirst = useIsFirstRender();

    const currentFlow = useSelector((state) => state.flow.flow);
    const flowMethods = useSelector((state) => state.flowMethodList.flowMethodList);

    const [needRefresh, setNeedRefresh] = useState(true);
    const [initiateVisible, setInitiateVisible] = useState(false);
    const [finalizeVisible, setFinalizeVisible] = useState(false);
    const [filteredFlowsMethods, setFilteredFlowsMethods] = useState([]);
    const [sourceMethods, setSourceMethods] = useState([]);
    const [mockFlowItems, setMockFlowItems] = useState([]);

    // global
    const getListApi = useCallback(() => {
        http.get('/integrations').then((integrations) => {
            dispatch(setAPIs(integrations.data.integrations));
            sessionStorage.setItem('integrations', JSON.stringify(integrations.data.integrations));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }, [dispatch]);

    // global
    const getListOperators = useCallback( async () => {
        try {
            const res = await http.get('/operators');
            const operators = res.data.operators;

            dispatch(setOperators(operators));
            sessionStorage.setItem('operators', JSON.stringify(operators));
        } catch (err) {
            console.error(`Error fetching operators: ${err.message}`);
        }
    }, [dispatch]);

    const updateFlow = useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const [flowResponse, flowMethodResponse] = await axios.all([
                http.get(createUrl(`/flow/${flow_id}/get`)),
                http.get(createUrl(`/flow/${currentFlow.id}/method/list`))
            ]);
            sessionStorage.removeItem('inputs');
            sessionStorage.removeItem('outputs');
            setNeedRefresh(true);
            dispatch(setFlow(flowResponse.data.flow));
            dispatch(setFlowMethodList(flowMethodResponse.data.flowMethods));
        } catch (error) {
            console.error(`Error updating flow: ${error.message}`);
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, flow_id, currentFlow.id, createUrl, setNeedRefresh]);

    useEffect(() => {
        setFilteredFlowsMethods(flowMethods.filter(item => item?.method?.name !== 'Output Port' && item?.method?.name !== 'Input Port'));
    }, [flowMethods, setFilteredFlowsMethods]);

    const updSourceMethod = useCallback(async () => {
        if (flow_id) {
            try {
                const res = await http.get(createUrl(`/flow/${flow_id}/method/connections/get`));
                setSourceMethods(res.data.sourceMethods);
            } catch (error) {
                console.error(`Error updating source method: ${error.message}`);
            }
        }
    }, [flow_id, createUrl]);

    // Get Flow
    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setLoading(true));
                const res = await http.get(createUrl(`/flow/${flow_id}/get`));
                const server = {
                    time: res.data.server_time ?? '',
                    timezone: res.data.server_timezone
                };
                dispatch(setServer(server));
                dispatch(setFlow(res.data.flow));
            } catch (error) {
                console.error(`Error fetching flow: ${error.message}`);
                navigate(ROUTES.HOME);
            } finally {
                dispatch(setLoading(false));
            }
        }

        fetchData();
    }, [dispatch, navigate, flow_id, updSourceMethod, createUrl]);

    // Get Flow Methods
    useLayoutEffect(() => {
        if (+currentFlow.id !== +flow_id) {
            return;
        }
        dispatch(setLoading(true));
        if (flow_id) {
            http.get(createUrl(`/flow/${flow_id}/method/list`)).then((res) => {
                dispatch(setFlowMethodList(res.data.flowMethods));
            }).finally(() => {
                dispatch(setLoading(false));
            });
        }
    }, [currentFlow.id, flow_id, dispatch, createUrl]);

    useEffect(() => {
        if (isFirst) {
            getListOperators();
        }
        if (needRefresh) {
            getListApi();
            updSourceMethod();
        }
    }, [needRefresh, dispatch, getListApi, getListOperators, isFirst, updSourceMethod]);

    const addIntegrationHandler = () => {
        setMockFlowItems((prev) => [...prev, { id: prev.length }]);
    };

    return (
        <Box>
            {currentFlow?.name && (<FlowTools
                flowId={flow_id}
                name={currentFlow.name}
                setInitiateVisible={setInitiateVisible}
                setFinalizationVisible={setFinalizeVisible}/>)}
            <InitiateFlowModal
                sourceMethods={sourceMethods}
                visible={initiateVisible}
                onClose={() => {setInitiateVisible(false)}}
                needRefresh={needRefresh}
                setNeedRefresh={setNeedRefresh}
            />
            <CompleteFlowModal
                needRefresh={needRefresh}
                setNeedRefresh={setNeedRefresh}
                visible={finalizeVisible}
                onClose={() => {setFinalizeVisible(false)}}
            />
            {/* change get methods */}
            <FlowMatchModal id={flow_id} />
            <Grid container spacing={'20px'} alignItems="flex-start">
                {filteredFlowsMethods.map((item) => (
                    <FlowItemAccordion key={item.id} item={item} updateFlow={updateFlow} tags={sourceMethods} />
                ))}

                {mockFlowItems.length > 0 && mockFlowItems.map((item) => (
                    <FlowItem key={item.id} updateFlow={updateFlow} />
                ))}

                <Grid item alignSelf="stretch" xs={12} sm={6} md={4} lg={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            maxHeight: '280px',
                            padding: '53px 0',
                            backgroundColor: '#414D65',
                            boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: '16px',
                            overflow: 'hidden',
                        }}
                    >
                        <CoolIcon/>
                        <Typography
                            sx={{
                                marginTop: '5px',
                                marginBottom: '26px',
                                fontWeight: 700,
                                fontSize: '15px',
                                lineHeight: '18px',
                                letterSpacing: '0.15em',
                                color: '#283348',
                            }}
                        >
                            ADD NEW API METHOD
                        </Typography>
                        <Button onClick={addIntegrationHandler} startIcon={<WhiteAddIcon/>} variant="contained">
                            Add API Method
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EditFlowPage;
