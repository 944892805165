import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import http from 'Utils/http';
import Logo_PNG from 'Assets/img/Infintegration_Logo_Primary.png';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { AddIcon } from 'Shared/Icons';
import { setFlow } from 'Actions/flow';
import { setLoading } from 'Actions/loading';
import { MyFlowsItem } from 'Components/shared/flows/MyFlowsItem';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { UserPreDefinedFlowsItem } from 'Components/shared/flows/UserPreDefinedFlowsItem';
import FlowCreateForm from 'Shared/flows/flows-interface/FlowCreateForm';
import FlowExportFlow from 'Shared/flows/flows-interface/FlowExportFlow';

const MyFlowsPage = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [flowsList, setFlowsList] = useState([]);
    const [userFlowsList, setUserFlowsList] = useState([]);
    const [visibleCreateFlowForm, setVisibleCreateFlowForm] = useState(false);
    const [needUpdateList, setNeedUpdateList] = useState(false);
    const [isImportFlow, setIsImportFlow] = useState(false);

    const toCreateFlow = (e) => {
        e.preventDefault();
        setVisibleCreateFlowForm(true);
    };

    const getListsFlows = useCallback(() => {
        dispatch(setLoading(true));
        axios.all([
            http.get('/flows'), http.get(`/user/flow/list`)
        ]).then(
            axios.spread((firstResponse, secondResponse) => {
                //need filter by status
                setFlowsList(
                    firstResponse.data.flows.filter((item) => {
                        return item.status === 'active';
                    })
                );
                setUserFlowsList(secondResponse.data.flows);
                dispatch(setLoading(false));
            })
        ).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    }, [dispatch, enqueueSnackbar]);

    const getMyFlowsList = useCallback(() => {
        http.get(`/user/flow/list`).then((res) => {
            dispatch(setFlow(res.data.flows));
            setUserFlowsList(res.data.flows);
            setNeedUpdateList(false);
        }).catch(() => {});
    }, [dispatch, setUserFlowsList, setNeedUpdateList]);

    useEffect(() => {
        if (needUpdateList) {
            getMyFlowsList()
        }
    }, [needUpdateList, getMyFlowsList]);

    const linkFlowToUser = (item) => {
        dispatch(setLoading(true));
        http.post(`/user/flow/${item.id}/copy`).then(() => {
            getMyFlowsList();
            dispatch(setLoading(false));
            enqueueSnackbar('Flow was added to your list', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    const deleteFlow = (item) => {
        dispatch(setLoading(true));
        http.delete(`/flow/${item.id}/delete`).then(() => {
            getMyFlowsList();
            dispatch(setLoading(false));
            enqueueSnackbar('Flow was deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    const unlinkFlow = (item) => {
        dispatch(setLoading(true));
        http.delete(`/flow/${item.id}/delete`).then(() => {
            getMyFlowsList();
            dispatch(setLoading(false));
            enqueueSnackbar('Flow was deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    const changeStatusFlow = (item) => {
        const params = {
            id: item.id,
            status: item.status,
        };
        dispatch(setLoading(true));
        http.post(`/flow/${item.id}/update`, params).then(() => {
            getMyFlowsList();
            dispatch(setLoading(false));
            enqueueSnackbar('Flow status was changed', {action: CustomSnackbar, variant: 'success'});
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {action: CustomSnackbar, variant: 'error'});
        });
    };

    useEffect(() => {
        getListsFlows();
    }, [getListsFlows]);

    return (
        <>
            <FlowExportFlow visible={isImportFlow} setVisible={setIsImportFlow} update={setNeedUpdateList}/>
            {visibleCreateFlowForm && (
                <FlowCreateForm
                    visibleCreateFlowForm={visibleCreateFlowForm}
                    setVisibleCreateFlowForm={setVisibleCreateFlowForm}
                />
            )}
            <Grid container sx={{height: '100%'}}>
                <Grid item
                      xs={12}
                      md={4}
                      lg={3}
                      sx={{
                          paddingBottom: '20px',
                          '@media (min-width: 1024px)': {
                              paddingRight: '20px',
                              paddingBottom: '0',
                          },
                      }}>
                    <Box
                        sx={{
                            height: 'calc(100vh - 30px)',
                            backgroundColor: '#f9f7f5',
                            border: '1px solid #FFFFFF',
                            boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: '16px',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '3rem',
                            position: 'sticky',
                            top: '15px'
                        }}
                    >
                        <img style={{width: '100%'}} src={Logo_PNG} alt=""/>
                    </Box>
                </Grid>

                <Grid item xs={12} md={8} lg={9}>
                    <Box
                        sx={{
                            height: '100%',
                            padding: '20px',
                            backgroundColor: '#FAFEFF',
                            border: '1px solid #FFFFFF',
                            boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: '16px',
                            overflow: 'hidden',
                        }}
                    >
                        <Box sx={{
                            position: 'relative'
                        }} >
                            <Button
                                sx={{
                                    position: 'absolute',
                                    right: '200px',
                                    bottom: '0',
                                    top: '0'
                                }}
                                onClick={() => setIsImportFlow(true)}
                                variant="contained"
                            >
                                Import Flow
                            </Button>
                            <Button
                                sx={{
                                    position: 'absolute',
                                    right: '0',
                                    bottom: '0',
                                    top: '0'
                                }}
                                onClick={(e) => {
                                    toCreateFlow(e);
                                }}
                                startIcon={<AddIcon/>}
                                variant="outlined"
                            >
                                Create New Flow
                            </Button>
                            <Typography
                                variant="h2"
                                color="primary.blackText"
                                sx={{
                                    marginBottom: '20px',
                                    textAlign: 'center',
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    lineHeight: '40px',
                                }}
                            >
                                My Active Flows:
                            </Typography>
                        </Box>

                        {!userFlowsList.length && (
                            <Typography
                                sx={{
                                    marginBottom: '20px',
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    color: '#D76A6A',
                                }}
                            >
                                Your flows list is empty
                            </Typography>
                        )}

                        {/* EDIT ITEM */}

                        {userFlowsList.map((item) => (
                            <MyFlowsItem
                                key={`${item.name}-${item.id}`}
                                item={item}
                                unlinkFlow={() => unlinkFlow(item)}
                                deleteFlow={deleteFlow}
                                changeStatusFlow={changeStatusFlow}
                                needUpdate={setNeedUpdateList}
                            />
                        ))}

                        <Typography
                            variant="h2"
                            color="primary.blackText"
                            sx={{
                                marginTop: '46px',
                                marginBottom: '20px',
                                textAlign: 'center',
                                fontWeight: 500,
                                fontSize: '20px',
                                lineHeight: '40px',
                            }}
                        >
                            Available Pre-Defined Flows:
                        </Typography>

                        {flowsList.map((item) => (
                            <UserPreDefinedFlowsItem
                                key={item.name}
                                item={item}
                                linkFlowToUser={() => linkFlowToUser(item)}
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default MyFlowsPage;
