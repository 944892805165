import React, { useState, useEffect } from 'react';
import http from 'Utils/http';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid, IconButton, Dialog, DialogContent } from '@mui/material';
import { Transition } from 'Shared/Transition';
import { CloseIcon } from 'Shared/Icons';
import { SelectActionsInitiateFlowModal } from 'Shared/flows/initiate-flow-modal/SelectActionsInitiateFlowModal';
import { useIsFirstRender } from 'Hooks/useIsFirstRender';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import CronConfigForm from 'Shared/flows/initiate-flow-modal/CronConfigForm';
import FlowDetails from 'Shared/flows/initiate-flow-modal/FlowDetails';
export const InitiateFlowModal = ({ visible, onClose, needRefresh, setNeedRefresh }) => {
    const isFirst = useIsFirstRender();
    const { enqueueSnackbar } = useSnackbar();
    const { createUrl } = useCreateUrl();
    const { flow_id } = useParams();
    const currentFlow = useSelector((state) => state.flow.flow);
    const server = useSelector((state) => state.server.server);

    const flow_id_redux = useSelector((state) => state.flow.flow?.id);
    const flowUrl = useSelector((state) => state.flow.flow?.url);

    const [inputs, setInputs] = useState([]);
    const [type, setType] = useState('url');

    useEffect(() => {
        const fetchData = async () => {
            if (!flow_id_redux || +flow_id_redux !== +flow_id || !needRefresh) {
                return;
            }

            if (sessionStorage.getItem('inputs') && isFirst) {
                const inputs = JSON.parse(sessionStorage.getItem('inputs'));
                setInputs([...inputs]);
                return;
            }

            try {
                const res = await http.get(createUrl(`/input/${flow_id}/fields`));
                const fields = Object.values(res.data.fields).sort((a, b) => a.request_field_id - b.request_field_id);
                setInputs(fields || []);
                sessionStorage.setItem('inputs', JSON.stringify(fields));
            } catch (err) {
                console.error(err, 'err');
            } finally {
                setNeedRefresh(false);
            }
        };

        fetchData();
    }, [flow_id, flow_id_redux, isFirst, setNeedRefresh, createUrl, needRefresh]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(flowUrl);
        enqueueSnackbar('URL copied to clipboard', {action: CustomSnackbar, variant: 'success'});
    };

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '75vw',
                    minHeight: '70vh',
                    backgroundColor: '#FAFEFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '16px',
                    boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                },
            }}
            open={visible}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <Box sx={{
                position: 'absolute',
                right: 0,
            }}>
                <IconButton size="large" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="large"/>
                </IconButton>
            </Box>
            <DialogContent
                sx={{
                    display: 'flex',
                    padding: '35px 30px 20px 25px',
                    overflow: 'hidden',
                }}
            >
                <Grid container direction="row">
                    <SelectActionsInitiateFlowModal setType={setType} type={type} />
                    {type === 'cron' ? (
                        <CronConfigForm
                            flow_id={flow_id}
                            cronCommand={currentFlow.cron}
                            serverTimeZone={server.timezone}
                            cronTimeZone={currentFlow.flow_timezone}
                            cronStatus={currentFlow.cron_status}/>
                    ) : (
                        <FlowDetails
                            flow_id={flow_id}
                            flowUrl={flowUrl}
                            inputs={inputs}
                            copyToClipboard={copyToClipboard}
                            setNeedRefresh={setNeedRefresh}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
