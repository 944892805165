import React from 'react';
import { Box, Typography } from '@mui/material';

export const SwitchOption = ({ label, isActive, isDefault = false, onClick, theme = 'light' }) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                padding: '5px 10px',
                width: '100%',
                backgroundColor: isActive ? '#289BEF' : 'transparent',
                borderRadius: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
            }}
        >
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Typography sx={{
                    fontWeight: '400',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '-0.078px',
                    color: theme === 'light' ? isActive ? '#FFFFFF' : '#414D65' : '#FFFFFF'
                }}>
                    {label}
                </Typography>
                {isDefault ? <Box sx={{
                    marginLeft: '10px',
                    width: '10px',
                    height: '10px',
                    background: '#1BBC6F',
                    borderRadius: '5px'
                }}></Box> : null}
            </Box>
        </Box>
    );
};
