import { SET_SERVER } from '../types';

const initialState = {
  server: {},
};

const server = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVER:
      return {
        ...state,
        server: action.payload,
      };
    default:
      return state;
  }
};

export default server;
